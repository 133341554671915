<template>
  <div class="mt--2">
    <KTPortlet>
      <template v-slot:title>
        <h3
          class="kt-portlet__head-title"
          style="width: 100%"
          v-if="informationData.article_information"
        >
          {{ informationData.information.purchase_id.model }} /
          {{ informationData.information.purchase_date.model.split('-')[2] }}.{{
            informationData.information.purchase_date.model.split('-')[1]
          }}.{{
            informationData.information.purchase_date.model.split('-')[0]
          }}
          / {{ informationData.article_information.l.brand.model }} /
          {{ informationData.article_information.l.brand_article_number.model }}
          /

          {{
            informationData.article_information.l.product_name_en.model
              ? informationData.article_information.l.product_name_en.model
              : informationData.article_information.l.product_name.model
          }}
        </h3>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading">
          <b-tabs v-model="tabs" content-class="mt-3 mb-7" lazy>
            <b-tab title="Information">
              <Information
                v-if="informationData"
                :initData="informationData"
                :saveData="save_data"
                :options="options"
                :formError.sync="form_error"
                :initDataFn="initDataFn"
              ></Information>
            </b-tab>
            <b-tab title="Invoice Document">
              <InvoiceDocument
                :initData="invoiceDocumentData"
                :options="options"
                :formError.sync="form_error"
                :initDataFn="initDataFn"
              ></InvoiceDocument>
            </b-tab>
            <b-tab title="logs">
              <SimpleTable
                :items="items"
                :fields="fields"
                :tableId="'purchase-target'"
              ></SimpleTable>
            </b-tab>
          </b-tabs>
        </b-overlay>

        <div class="console" v-if="ifConsole && tabs != 2">
          <b-overlay :show="loading">
            <template v-if="tabs == 0">
              <b-button
                v-if="
                  status == 'Reserved' ||
                    status == 'Confirmed' ||
                    status == 'Delivered' ||
                    status == 'Partial Delivered'
                "
                variant="primary"
                class="mr-2"
                :disabled="disabled"
                @click="save(informationData.information.status.display_text)"
                >Save
                <i class="far fa-save"></i>
              </b-button>
              <ConfirmDelivery
                v-if="status == 'Confirmed' || status == 'Partial Delivered'"
                :id="id"
                :maxQty="
                  informationData.article_information.qty.model -
                    informationData.delivery_qty
                "
                :reserve_no="informationData.information.delivery_to.model"
                :purchase_note="
                  informationData.article_information.purchase_note.model
                "
                :initDataFn="initDataFn"
                :disabled="disabled"
              ></ConfirmDelivery>
              <ConfirmPurchase
                v-if="status == 'Reserved'"
                :id="id"
                :maxQty="informationData.article_information.qty.model"
                :disabled="disabled"
                :purchase_date="informationData.information.purchase_date.model"
                :supplier_name="informationData.information.supplier_name.model"
                :delivery_to="informationData.information.delivery_to.model"
                :reserve_no="informationData.information.reserve_no.model"
                :purchase_price="
                  informationData.article_information.purchase_price.model
                "
                :purchase_note="
                  informationData.article_information.purchase_note.model
                "
                :initDataFn="initDataFn"
              ></ConfirmPurchase>
              <b-button
                v-if="status == 'Reserved' || status == 'Confirmed'"
                variant="dark"
                class="mr-2"
                @click="voidTarget()"
                :disabled="disabled"
                >Void Purchase
                <i class="fas fa-ban"></i>
                <!-- <b-icon icon="slash-circle" aria-hidden="true"></b-icon
            > -->
              </b-button>
              <b-button
                v-if="status == 'Partial Delivered'"
                variant="success"
                class="mr-2"
                @click="deliveryShowModal = true"
                :disabled="disabled"
                >Delivery Done
                <i class="far fa-check-circle"></i>
              </b-button>
              <router-link
                :to="{
                  name: 'purchase-target-detail-index',
                  params: {
                    purchaseId: purchase_target_id
                  }
                }"
                class="btn btn-secondary mr-2"
                >Back to Target
                <b-icon icon="reply-all-fill" aria-hidden="true"></b-icon
              ></router-link>
            </template>
            <template v-if="tabs == 1">
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="invoiceDocumentDisabled"
                @click="invoiceDocumentSave()"
                >Save
                <i class="far fa-save"></i>
              </b-button>
            </template>
            <router-link
              :to="{
                name: 'purchase-overview-list'
              }"
              class="btn btn-secondary mr-2"
              >Back to List
              <b-icon icon="reply-fill" aria-hidden="true"></b-icon
            ></router-link>
          </b-overlay>
        </div>

        <b-modal
          v-model="deliveryShowModal"
          centered
          no-close-on-backdrop
          scrollable
          title="Delivery Done"
          id="void_target"
          ref="modal"
        >
          <b-overlay :show="deliveryModalloading">
            <p>Are you sure all the delivery is done from this supplier?</p>
          </b-overlay>
          <template v-slot:modal-footer="{ close }">
            <b-button
              variant="primary"
              @click="deliverySubmit()"
              :disabled="deliverySubmitDisabled"
            >
              YES
            </b-button>
            <b-button variant="secondary" @click="close()"> NO </b-button>
          </template></b-modal
        >
        <b-modal
          v-model="showModal"
          centered
          no-close-on-backdrop
          scrollable
          title="Void Purchase"
          id="void_target"
          ref="modal"
        >
          <b-overlay :show="modalloading">
            <p class="reconfirm">Are you sure to void this purchase target?</p>
          </b-overlay>
          <template v-slot:modal-footer="{ close }">
            <b-button
              variant="primary"
              @click="submit()"
              :disabled="submitDisabled"
            >
              YES
            </b-button>
            <b-button variant="secondary" @click="close()"> NO </b-button>
          </template></b-modal
        >
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import Information from '@/views/content/Detail/PurchaseoOverview/Information/Index.vue';
import apiBus from '@/common/apiBus/index';
import { mapState } from 'vuex';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SimpleTable from '@/components/Table/Simple';
import ConfirmDelivery from '@/views/content/Detail/PurchaseoOverview/Information/ConfirmDelivery.vue';
import ConfirmPurchase from '@/views/content/Detail/PurchaseoOverview/Information/ConfirmPurchase.vue';
import InvoiceDocument from '@/views/content/Detail/PurchaseoOverview/Information/InvoiceDocument.vue';
import JwtService from '@/common/services/jwt';
import _ from 'lodash';
// import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'purchaseOverviewDetail',
  components: {
    KTPortlet,
    Information,
    SimpleTable,
    ConfirmDelivery,
    ConfirmPurchase,
    InvoiceDocument
  },
  data() {
    return {
      id: null,
      loading: false,
      disabled: false,
      invoiceDocumentDisabled: false,
      informationData: {},
      invoiceDocumentData: {},
      save_data: {},
      items: [],
      ifConsole: false,
      status: '',
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      deliveryModalloading: false,
      deliverySubmitDisabled: false,
      deliveryShowModal: false,
      purchase_target_id: null,
      form_error: false,
      tabs: 0,
      fields: [
        {
          key: 'time',
          label: 'Time'
        },
        {
          key: 'company',
          label: 'Supplier Name'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'modification',
          label: 'Modification',
          variant: 'mx-500'
        }
      ],
      options: {
        supplier_name: [],
        channel: [],
        logistics: [],
        refundable: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 2 },
          { label: 'Uncertain', value: 3 }
        ]
      }
    };
  },

  methods: {
    voidTarget() {
      this.showModal = true;
      this.submitDisabled = false;
    },
    submit() {
      this.modalloading = true;
      this.submitDisabled = true;
      apiBus.purchase
        .purchaseOverviewVoided({
          purchase_overview_id: this.id
        })
        .then(data => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          this.showModal = false;
          this.initDataFn();
        })
        .catch(error => {
          console.log(error);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    deliverySubmit() {
      this.deliveryModalloading = true;
      this.deliverySubmitDisabled = true;
      apiBus.purchase
        .purchaseOverviewDeliveryDone({
          purchase_overview_id: this.id
        })
        .then(data => {
          console.log(data);
          this.deliveryModalloading = false;
          this.deliverySubmitDisabled = false;
          this.deliveryShowModal = false;
          this.initDataFn();
        })
        .catch(error => {
          console.log(error);
          this.deliveryModalloading = false;
          this.deliverySubmitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    handleData(data) {
      let handle_data = {};
      let {
        // priority,
        delivery_qty,
        cps_order_number,
        delivery_to,
        id,
        last_modification_at,
        last_modification_by,
        order_date,
        partner_order_id,
        product_info,
        purchase_date,
        purchase_id,
        purchase_note,
        status,
        channel_name,
        supplier_name,
        supplier_order_numbe,
        supplier_type,
        purchaser,
        purchase_price,
        purchase_target_id,
        qty,
        reserve_no,
        delivery_list,
        stored_qty,
        reject_qty,
        settlement_info
        // invoice_status,
        // refundable
      } = data;
      let {
        brand,
        brand_article_number,
        product_name,
        product_name_en,
        ean_number,
        image_url,
        color_name,
        color,
        org_color,
        size,
        pos_article_no,
        merchant_note
      } = product_info;
      let { settlement_type, payment_person, payment_by } = settlement_info;

      this.purchase_target_id = purchase_target_id;
      let supplierData;
      status == 'Reserved'
        ? (supplierData = {
            purchase_target_id: purchase_target_id,
            status: 10
          })
        : (supplierData = {
            purchase_target_id: purchase_target_id
          });
      apiBus.purchase
        .getSupplierList(supplierData)
        .then(data => {
          let options = [];
          Object.keys(data.data.data.supplier_list).forEach(item => {
            options.push({
              label: item,
              value: item.replace(/ /g, '_'),
              index: 1,
              disabled: true
            });
            // console.log(item);
            data.data.data.supplier_list[item].forEach(x => {
              options.push({
                label: x.label,
                value: x.value,
                index: 2
              });
            });
          });
          this.options.supplier_name = options;
          if (this.informationData.information.supplier_name.options == 0)
            this.informationData.information.supplier_name.options = this.options.supplier_name;
          // 给Order Type赋值
        })
        .catch(error => {
          console.log(error);
        });
      let channelData;
      status == 'Reserved'
        ? (channelData = {
            supplier: supplier_name,
            status: 10
          })
        : (channelData = {
            supplier: supplier_name
          });

      apiBus.purchase
        .getChannelList(channelData)
        .then(data => {
          this.options.channel = data.data.data.channel_list;
          if (this.informationData.information.channel.options == 0)
            this.informationData.information.channel.options = this.options.channel;
        })
        .catch(error => {
          console.log(error);
        });
      handle_data = {
        information: {
          purchase_id: {
            type: 'input',
            text: 'Purchase ID',
            model: purchase_id,
            disabled: true
          },
          status: {
            type: 'text',
            text: 'Status',
            display_text: status,
            // display_text: 'Partial Delivered',
            class: 'status-text status',
            text_class: 'text color_' + status.replace(' ', '_')
            // text_class: 'text color_' + 'Partial Delivered'.replace(' ', '_')
          },
          purchase_date: {
            type: 'date',
            text: 'Purchase Date',
            model: purchase_date,
            disabled: true,
            hide: false
          },
          // priority: {
          //   type: 'input',
          //   text: 'Order Priority',
          //   model: priority == 1 ? 'VIP' : 'Normal',
          //   disabled: true
          // },
          cps_order_number: {
            type: 'input',
            text: 'Order ID',
            model: cps_order_number,
            disabled: true
          },

          // supplier_name: {
          //   type: 'select',
          //   text: 'Supplier Name',
          //   model: supplier_name,
          //   disabled: true,
          //   options: this.options.supplier_name
          // },
          // l: {
          reserve_no: {
            type: 'input',
            text: 'Reserve No.',
            model: reserve_no,
            disabled: true
          },
          supplier_name: {
            type: 'select',
            text: 'Supplier Name',
            model: supplier_name,
            disabled: true,
            hideLoading: true,
            options: this.options.supplier_name,
            input: this.changeSupplier,
            onSearch: this.onSearch
          },
          delivery_to: {
            type: 'select',
            text: 'Delivery to',
            model: delivery_to,
            disabled: true,
            options: this.baseConfig.purchase_delivery_to
          },
          channel: {
            type: 'select',
            text: 'Channel',
            model: channel_name,
            disabled: true,
            options: this.options.channel
          }
        },

        article_information: {
          l: {
            brand: {
              type: 'input',
              text: 'Brand',
              model: brand,
              disabled: true
            },
            brand_article_number: {
              type: 'input',
              text: 'Brand Article Number',
              model: brand_article_number,
              disabled: true
            },
            product_name: {
              type: 'input',
              text: 'Article Name CN',
              model: product_name,
              disabled: true
            },
            product_name_en: {
              type: 'input',
              text: 'Article Name EN',
              model: product_name_en,
              disabled: true
            }
          },
          r: {
            image_url: {
              type: 'img',
              text: '',
              url: image_url
            }
          },
          ean_number: {
            type: 'input',
            text: 'EAN Number',
            model: ean_number,
            disabled: true
          },
          color: {
            type: 'input',
            text: 'Color',
            model: color_name,
            disabled: true,
            icon_color: color,
            hover: org_color,
            hide: false
          },
          pos_article_no: {
            type: 'input',
            text: 'POS Article No.',
            model: pos_article_no,
            disabled: true
          },
          size: {
            type: 'input',
            text: 'Size',
            model: size,
            disabled: true
          },

          qty: {
            type: 'input',
            text: 'Purchase Quantity',
            model: qty,
            disabled: true
          },
          purchase_price: {
            type: 'input',
            text: 'Purchase Price',
            model: purchase_price,
            icon_text: '€',
            // purchase_price: purchase_price
            disabled: true
          },

          stored_qty: {
            type: 'input',
            text: 'Total Storage Quantity',
            model: parseFloat(stored_qty),
            input_type: 'number',
            min: 0,
            disabled: true,
            show: true
          },

          reject_qty: {
            type: 'input',
            text: 'Total Rejection Quantity',
            model: parseFloat(reject_qty),
            input_type: 'number',
            min: 0,
            disabled: true,
            show: true
          },

          purchase_note: {
            type: 'textarea',
            text: 'Purchase Note',
            model: purchase_note,
            rows: 8,
            disabled: true,
            placeholder: ''
          },
          merchant_note: {
            type: 'textarea',
            text: 'Merchant Note',
            model: merchant_note,
            rows: 8,
            disabled: true,
            placeholder: ''
          }
        },

        logistics_information: [],

        settlement_information: {},

        purchaser: {
          purchaser: {
            type: 'input',
            text: 'Purchaser',
            model: purchaser,
            disabled: true
          },
          placeholder_1: {
            type: 'placeholder'
          },
          last_modification_at: {
            type: 'input',
            text: 'Last Modification at',
            model: last_modification_at,
            disabled: true
          },
          last_modification_by: {
            type: 'input',
            text: 'Last Modification by',
            model: last_modification_by,
            disabled: true
          }
        },

        // cps_order_number: cps_order_number,
        id: id,
        order_date: order_date,
        partner_order_id: partner_order_id,
        supplier_order_numbe: supplier_order_numbe,
        supplier_type: supplier_type,
        delivery_qty: delivery_qty
      };
      // console.log(handle_data);
      // this.status = status;
      this.status = status;
      if (Object.keys(settlement_info).length > 0) {
        console.log('有数据');
        handle_data.settlement_information = {
          settlement_type: {
            type: 'radio',
            md: 12,
            text: 'Settlement Type',
            model: settlement_type,
            options: [
              { label: 'Partner Contract', value: 1 },
              { label: 'Direct Payment', value: 2 }
            ],
            disabled: true
          },
          payment_by: {
            type: 'input',
            text: 'Payment by',
            model: payment_by,
            show: settlement_type == 2,
            md: settlement_type == 2 ? '6' : '0',
            disabled: true
          },
          payment_person: {
            type: 'input',
            text: 'Payment Person',
            model: payment_person,
            show: settlement_type == 2,
            md: settlement_type == 2 ? '6' : '0',
            disabled: true
          }
        };
        console.log(settlement_type);
      } else {
        console.log('无数据');
      }
      delivery_list.forEach(key => {
        handle_data.logistics_information.push({
          id: key.id,
          article_review_id: key.article_review_id,
          list: {
            delivery_date: {
              type: 'date',
              text: 'Delivery Date',
              model: key.delivery_date,
              disabled: true
            },
            delivery_qty: {
              type: 'input',
              text: 'Deliver Quantity',
              model: key.delivery_qty,
              disabled: true,
              md: '6',
              show: true
            },
            stored_qty: {
              type: 'input',
              text: 'Storage Quantity',
              model: key.stored_qty,
              disabled: true
            },
            reject_qty: {
              type: 'input',
              text: 'Rejection Quantity',
              model: key.reject_qty,
              disabled: true
            },
            logistics: {
              type: 'select',
              text: 'Logistics',
              model: key.logistics == 0 ? null : key.logistics,
              disabled:
                key.is_edit == 1 &&
                (status == 'Reserved' ||
                  status == 'Confirmed' ||
                  status == 'Delivered' ||
                  status == 'Partial Delivered')
                  ? false
                  : true,
              required:
                key.is_edit == 1 &&
                (status == 'Reserved' ||
                  status == 'Confirmed' ||
                  status == 'Delivered' ||
                  status == 'Partial Delivered')
                  ? true
                  : false,
              options: this.options.logistics,
              clearable: false,
              inputFn: this.logisticsInout
            },

            tracking_no: {
              type: 'input',
              text: 'Tracking No.',
              model: key.tracking_no,
              disabled:
                key.is_edit == 1 &&
                (status == 'Reserved' ||
                  status == 'Confirmed' ||
                  status == 'Delivered' ||
                  status == 'Partial Delivered')
                  ? false
                  : true,
              required:
                key.is_edit == 1 &&
                (status == 'Reserved' ||
                  status == 'Confirmed' ||
                  status == 'Delivered' ||
                  status == 'Partial Delivered')
                  ? true
                  : false,
              md: key.logistics != 'pick_up' ? '6' : '0',
              show: key.logistics != 'pick_up' ? true : false
            },
            pick_up_by: {
              type: 'input',
              text: 'Pick up by',
              model: key.pick_up_by,
              disabled:
                key.is_edit == 1 &&
                (status == 'Reserved' ||
                  status == 'Confirmed' ||
                  status == 'Delivered' ||
                  status == 'Partial Delivered')
                  ? false
                  : true,
              required:
                key.is_edit == 1 &&
                (status == 'Reserved' ||
                  status == 'Confirmed' ||
                  status == 'Delivered' ||
                  status == 'Partial Delivered')
                  ? true
                  : false,
              md: key.logistics == 'pick_up' ? '6' : '0',
              show: key.logistics == 'pick_up' ? true : false
            }
          }
        });
      });

      return this.handleStatus(handle_data, status);
    },

    onSearch(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      apiBus.purchase
        .getSupplierList({
          keyword: search
        })
        .then(data => {
          vm.informationData.information.supplier_name.options =
            data.data.data.supplier_list['Other Supplier'];
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.informationData.information.supplier_name.options = [];
          loading(false);
        });
    }, 350),

    handleInvoiceDocumentData(data) {
      // let { invoice_status, refundable } = data;
      console.log(data);
      let handle_data = {};
      handle_data = {
        upload: {
          type: 'upload', // 上传
          md: '12',
          accept: 'application/pdf',
          list: data, // 默认值
          state: null, // 当前按钮状态
          input: this.upload, // input 事件 上传文件
          uploadCheck: this.uploadCheck,
          uploadDelete: this.uploadDelete,
          disabled: false
        }
      };

      return handle_data;
    },

    handleStatus(data, status) {
      data.article_information.purchase_note.disabled = false;
      switch (status) {
        case 'Reserved':
          data.article_information.purchase_price.disabled = false;
          data.article_information.purchase_note.disabled = false;
          data.information.delivery_to.disabled = false;
          data.information.purchase_date.disabled = false;
          data.information.supplier_name.disabled = false;
          data.information.channel.disabled = false;
          data.article_information.stored_qty.show = false;
          data.article_information.reject_qty.show = false;
          break;
        case 'Confirmed':
          data.article_information.purchase_price.disabled = false;
          data.article_information.stored_qty.show = false;
          data.article_information.reject_qty.show = false;
          break;
        case 'Delivered':
          // data.article_information.purchase_note.disabled = false;
          data.article_information.purchase_price.disabled = false;
          break;
        case 'Partial Delivered':
          data.article_information.purchase_price.disabled = false;
          break;
        case 'VOIDED':
          data.article_information.purchase_note.disabled = true;
          break;
      }

      return data;
    },

    changeSupplier(e) {
      this.informationData.information.channel.options = [];
      this.informationData.information.channel.model = null;
      let channelData;
      this.informationData.information.status.display_text == 'Reserved'
        ? (channelData = {
            supplier: e,
            status: 10
          })
        : (channelData = {
            supplier: e
          });
      apiBus.purchase
        .getChannelList(channelData)
        .then(data => {
          console.log(data);
          this.informationData.information.channel.options =
            data.data.data.channel_list;
        })
        .catch(error => {
          console.log(error);
        });
    },

    upload(event, item) {
      if (event != null && event.length > 0) {
        item.state = 'uploading';
        var fileData = new FormData();
        fileData.append('purchase_overview_id', this.id);
        event.forEach((item, i) => {
          fileData.append(`file[${i}]`, item);
        });
        apiBus.purchase
          .uploadPurchaseOverviewInvoice(fileData)
          .then(data => {
            console.log(data);
            item.state = 'success';
            this.initDataFn();
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 5, //初始提示作用于定时器
              alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
              alertText: 'Successfully!' // 提示内容
            });
          })
          .catch(error => {
            console.log(error);
            item.state = 'error';
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 9999999999, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText: error.data.message // 提示内容
            });
          });
      }

      return 'Upload';
    },
    uploadCheck(id) {
      var childWin = window.open(
        `/rest/api/purchase/print-purchase-invoice?purchase_invoice_file_id=${id}&token=${JwtService.getToken()}`,
        'newwindow',
        'height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
      );
      childWin.onload = function() {
        childWin.print();
      };
    },
    uploadDelete(invoice_id, invoice_file_id) {
      console.log(invoice_file_id);
      console.log(invoice_id);
      // this.initDataFn();
      let data = {
        purchase_invoice_id: invoice_id,
        purchase_invoice_file_id: invoice_file_id
      };
      apiBus.purchase
        .deleteOverviewPurchaseInvoice(data)
        .then(data => {
          console.log(data);
          this.initDataFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully Deleted!' // 提示内容
          });
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });
    },

    save(status) {
      let data = {
        purchase_overview_id: this.id
      };
      let item = this.informationData;

      switch (status) {
        case 'Reserved':
          data.purchase_note = item.article_information.purchase_note.model;
          data.purchase_price = item.article_information.purchase_price.model;
          data.purchase_date = item.information.purchase_date.model;
          data.supplier = item.information.supplier_name.model;
          data.channel = item.information.channel.model;
          data.delivery_address = item.information.delivery_to.model;
          break;
        case 'Confirmed':
          data.purchase_price = item.article_information.purchase_price.model;
          break;
        case 'Partial Delivered':
          data.purchase_price = item.article_information.purchase_price.model;
          break;
        case 'Delivered':
          data.purchase_price = item.article_information.purchase_price.model;
          break;
      }
      if (
        data.purchase_price &&
        isNaN(data.purchase_price.replace('.', '').replace(',', '.'))
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 9999999999, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Please fill in the correct Purchase Price !' // 提示内容
        });
        return false;
      }
      data.reserve_no = item.information.reserve_no.model;
      data.purchase_note = item.article_information.purchase_note.model;
      let delivery_list = [];
      this.informationData.logistics_information.forEach(items => {
        var obj = {};
        if (!items.list.logistics.disabled) {
          obj.id = items.id;
          obj.logistics = items.list.logistics.model;
          if (items.list.logistics.model != 'pick_up') {
            if (items.list.tracking_no.model != '') {
              obj.tracking_no = items.list.tracking_no.model;
              obj.pick_up_by = '';
              this.form_error = false;
            } else {
              this.form_error = true;
              this.$store.dispatch(PROMPT_STATUS, {
                dismissCountDown: 9999999999, //初始提示作用于定时器
                alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
                alertText: 'Fields marked with * are required!' // 提示内容
              });
              return false;
            }
          } else {
            if (items.list.pick_up_by.model != '') {
              obj.pick_up_by = items.list.pick_up_by.model;
              obj.tracking_no = '';
              this.form_error = false;
            } else {
              this.form_error = true;
              this.$store.dispatch(PROMPT_STATUS, {
                dismissCountDown: 9999999999, //初始提示作用于定时器
                alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
                alertText: 'Fields marked with * are required!' // 提示内容
              });
              return false;
            }
          }
        }
        delivery_list.push(obj);
      });
      if (!this.form_error) {
        data.delivery_list = delivery_list;
        console.log(data);
        this.loading = true;
        this.disabled = true;
        apiBus.purchase
          .purchaseOverviewSave(data)
          .then(data => {
            console.log(data);
            this.initDataFn();
            this.loading = false;
            this.disabled = false;

            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 5, //初始提示作用于定时器
              alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
              alertText: 'Successfully!' // 提示内容
            });
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.disabled = false;
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 9999999999, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText: error.message // 提示内容
            });
          });
      }
    },
    invoiceDocumentSave() {
      // console.log(this.invoiceDocumentData.upload.list);
      let invoice_no_arr = [];
      this.invoiceDocumentData.upload.list.forEach(item => {
        console.log(item.invoice_no);
        if (item.invoice_no != '') {
          invoice_no_arr.push(item.invoice_no);
        }
      });
      let len = invoice_no_arr.length;
      let setLen = [...new Set(invoice_no_arr)].length;
      if (len != setLen) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 9999999999, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'The invoice number is repeated, please fill in again !' // 提示内容
        });
        return false;
      }

      this.invoiceDocumentDisabled = true;
      this.loading = true;
      apiBus.purchase
        .savePurchaseInvoiceFile({
          file_info: this.invoiceDocumentData.upload.list
        })
        .then(data => {
          console.log(data);
          this.invoiceDocumentDisabled = false;
          this.initDataFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch(error => {
          console.log(error);
          this.invoiceDocumentDisabled = false;
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });
    },
    logisticsInout(e, i) {
      if (e == 'pick_up') {
        this.informationData.logistics_information[
          i
        ].list.tracking_no.show = false;
        this.informationData.logistics_information[i].list.tracking_no.md = '0';
        this.informationData.logistics_information[
          i
        ].list.pick_up_by.show = true;
        this.informationData.logistics_information[i].list.pick_up_by.md = '6';
      } else {
        this.informationData.logistics_information[
          i
        ].list.tracking_no.show = true;
        this.informationData.logistics_information[i].list.tracking_no.md = '6';
        this.informationData.logistics_information[
          i
        ].list.pick_up_by.show = false;
        this.informationData.logistics_information[i].list.pick_up_by.md = '0';
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    initDataFn() {
      this.loading = true;
      let loading_l = 0;
      this.invoiceDocumentLoading;
      apiBus.purchase
        .purchaseOverviewDetail({
          purchase_overview_id: this.id
        })
        .then(data => {
          if (loading_l == 1) {
            this.loading = false;
          } else {
            loading_l += 1;
          }
          console.log('loading_l:' + loading_l);
          this.ifConsole ? '' : (this.ifConsole = true);
          this.informationData = this.handleData(data.data.data);
        })
        .catch(error => {
          if (loading_l == 1) {
            this.loading = false;
          } else {
            loading_l += 1;
          }
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      apiBus.purchase
        .purchaseOverviewLogs({
          purchase_overview_id: this.id
        })
        .then(data => {
          this.items = data.data.data.list;
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      apiBus.purchase
        .getPurchaseInvoiceFileInfo({
          purchase_overview_id: this.id
        })
        .then(data => {
          if (loading_l == 1) {
            this.loading = false;
          } else {
            loading_l += 1;
          }
          console.log('loading_l:' + loading_l);
          this.invoiceDocumentData = this.handleInvoiceDocumentData(
            data.data.data
          );
          console.log('批量上传');
        })
        .catch(error => {
          if (loading_l == 1) {
            this.loading = false;
          } else {
            loading_l += 1;
          }
          console.log(error);
        });
    },
    modelHide() {
      if (document.getElementsByClassName('form_error').length > 0) {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
          scroll_top = document.documentElement.scrollTop;
        } else if (document.body) {
          scroll_top = document.body.scrollTop;
        }
        var divTop = document
          .getElementsByClassName('form_error')[0]
          .getBoundingClientRect().top;
        console.log(divTop);
        window.scrollTo(
          0,
          divTop + scroll_top - document.documentElement.clientHeight / 3
        );
      }
    },
    init() {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then(data => {
            console.log('getBaseConfig数据缓存vuex2');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch(error => {
            console.log(error);
          });
      }
      let { purchaseId } = this.$route.params;
      this.id = purchaseId;

      this.initDataFn();
    }
  },

  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    })
  },

  mounted() {
    this.init();
    apiBus.purchase
      .getExpressList()
      .then(data => {
        this.options.logistics = data.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  watch: {
    'options.logistics': function(val) {
      console.log('监听生效');
      if (Object.keys(this.informationData).length > 0) {
        this.informationData.logistics_information.forEach(key => {
          key.list.logistics.options = val;
        });
      }
    },
    form_error: function() {
      this.$nextTick(() => {
        this.modelHide();
      });
    },
    async 'informationData.information.supplier_name.model'(n, o) {
      if (n == null && o != null) {
        const { data } = await apiBus.purchase.getSupplierList();
        this.informationData.information.supplier_name.options =
          data.data.supplier_list['Other Supplier'];
      }
    }
  }
};
</script>
<style scoped>
.btn-success {
  color: #fff;
}
</style>
