<template>
  <div style="display: inline-block">
    <b-button
      variant="primary"
      class="mr-2 confirm-purchase"
      :disabled="disabled"
      :size="size"
      @click="onShowModal()"
      >Confirm
      <i class="far fa-check-circle"></i>
      <!-- <b-icon icon="folder-symlink" aria-hidden="true">
        </b-icon
    > -->
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Confirm"
      id="void_target"
      ref="modal"
    >
      <b-overlay :show="modalloading">
        <p>Please confirm the final purchase quantity</p>
        <b-row>
          <b-col md="12" class="my-1">
            <b-form-group
              label="Quantity"
              label-for="quantity"
              class="search-flex quantity"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> / {{ maxQty }} </b-input-group-text>
                </template>
                <b-form-input
                  v-model="qty"
                  type="number"
                  min="0"
                  :max="maxQty"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';

export default {
  name: 'PurchaseoOverviewPurchase',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      qty: null
    };
  },
  props: [
    'maxQty',
    'id',
    'size',
    'disabled',
    'purchase_note',
    'purchase_price',
    'purchase_date',
    'supplier_name',
    'delivery_to',
    'reserve_no',
    'initDataFn'
  ],
  // inheritAttrs: false,
  components: {},
  methods: {
    onShowModal() {
      this.showModal = true;
      this.submitDisabled = false;
      this.qty = this.maxQty;
    },
    submit() {
      this.modalloading = true;
      this.submitDisabled = true;
      var data = {
        purchase_overview_id: this.id,
        qty: this.qty,
        purchase_note: this.purchase_note,
        purchase_price: this.purchase_price,
        purchase_date: this.purchase_date,
        supplier: this.supplier_name,
        reserve_no: this.reserve_no,
        delivery_address: this.delivery_to
      };

      console.log(data);
      apiBus.purchase
        .purchaseOverviewConfirm(data)
        .then(data => {
          console.log(data);
          this.modalloading = false;
          this.submitDisabled = false;
          // location.reload();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
          this.initDataFn();
        })
        .catch(error => {
          console.log(error.message);
          this.modalloading = false;
          this.submitDisabled = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {}
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
</style>
